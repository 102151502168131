import API from "../config/api";
import { message } from "antd";
import { t } from "i18next";

export const exportTimeReport = async (requestData) => {
  try {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      message.error(t("common.session_expiered"));
      return;
    }

    const response = await API.post(
      `api/timereports/generatereport`,
      requestData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};
