import React, { useEffect, useState } from "react";
import moment from "moment";

const Timer = ({ givenTime }) => {
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const timeDifference = moment.duration(currentTime.diff(moment(givenTime)));

  const hours = Math.abs(timeDifference.hours());
  const minutes = Math.abs(timeDifference.minutes()) % 60;
  const seconds = Math.abs(timeDifference.seconds()) % 60;

  return (
    <div>
      {hours}:{minutes < 10 ? "0" : ""}
      {minutes}:{seconds < 10 ? "0" : ""}
      {seconds}
    </div>
  );
};

export default Timer;
