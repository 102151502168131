import API from "../config/api";

export const login = async (loginData) => {
  try {
    const response = await API.post(`api/users/login`, loginData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
