import API from "../config/api";
import { message } from "antd";
import { t } from "i18next";

export const addAnEmployee = async (data) => {
  const authToken = localStorage.getItem("authToken");

  if (!authToken) {
    message.error(t("common.session_expiered"));
    return;
  }

  try {
    const response = await API.post(`api/users/add`, data, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data.accessUrl; // TODO: replace the _id with accessurl
  } catch (error) {
    throw error;
  }
};
