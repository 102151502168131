import React, { useState } from "react";
import { Button, Form, Input, message, Row, Col, Upload, Modal } from "antd";
import {
  DownloadOutlined,
  UploadOutlined,
  CopyOutlined,
  UserOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { t } from "i18next";
import Papa from "papaparse";
import { bukCreateEmployee } from "../service/BulkCreateEmployee";
import { exportEmployeeList } from "../service/ExportEmployeeList";
import { addAnEmployee } from "../service/AddAnEmployee";

const AddEmployee = () => {
  const [employeCSV, setemployeCSV] = useState(null);
  const [isAddEmployeeModalVisible, setIsAddEmployeeModalVisible] =
    useState(false);
  const [
    isUploadConfirmationModalVisible,
    setIsUploadConfirmationModalVisible,
  ] = useState(false);
  const [isUploadSuccessModalVisible, setIsUploadSuccessModalVisible] =
    useState(false);
  const [addEmployeeModalContent, setAddEmployeeModalContent] = useState("");
  const [uploadModalContent, setUploadModalContent] = useState("");
  const [employeeCount, setEmployeeCount] = useState(0);
  const [copyButtonText, setCopyButtonText] = useState(
    t("admin_dashboard.copy_content")
  );
  const [form] = Form.useForm();

  const downloadCsvTemplate = () => {
    const link = document.createElement("a");
    link.href = require("../assets/template.csv");
    link.download = "テンプレート.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const UploadCsv = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", employeCSV);

      var response = await bukCreateEmployee(formData);
      setIsUploadConfirmationModalVisible(false);
      var translationString = t("admin_dashboard.upload_success");
      var replacedString = translationString
        .replace(/\$/g, response.data.success_count)
        .replace(/%/g, response.data.all_count)
        .replace(/#/g, response.data.duplicate_count);

      var modalContent = replacedString;
      setUploadModalContent(modalContent);
      setIsUploadSuccessModalVisible(true);
    } catch (error) {
      message.error("Operation unsuccessful. Please try again."); // Display error message
    }
  };

  const handleUploadCsv = (file) => {
    setemployeCSV(file);
    const reader = new FileReader();

    reader.onload = function (event) {
      const result = event.target.result;
      const employeesData = parseCsvData(result);
      const count = employeesData.length;
      setEmployeeCount(count);

      setIsUploadConfirmationModalVisible(true);
    };

    reader.readAsText(file);
  };

  const handleExportCsv = async () => {
    var response = await exportEmployeeList();
    var data = response.data;

    const parsedData = Papa.parse(data, { header: true });
    const csv = Papa.unparse(parsedData.data);

    const csvContent = "\uFEFF" + csv; // Add Byte Order Mark (BOM) for UTF-8 encoding
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", t("admin_dashboard.all_emloyees"));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const parseCsvData = (csvData) => {
    const parsedData = Papa.parse(csvData, {
      header: true,
      skipEmptyLines: true,
    });

    if (parsedData.errors.length > 0) {
      console.error("Error parsing CSV:", parsedData.errors);
      return [];
    }

    return parsedData.data.map((row) => ({
      name: row.name,
      email: row.email,
    }));
  };

  const handleAddEmployee = async (values) => {
    const data = {
      email: values.email,
      fullname: values.name,
    };

    try {
      const accessUrl = await addAnEmployee(data);
      if (accessUrl) {
        setAddEmployeeModalContent(accessUrl);
        setIsAddEmployeeModalVisible(true);
      }
    } catch (error) {
      message.error(t("admin_dashboard.add_employee_error"));
    }
  };

  const handleAddEmployeeModalClose = () => {
    setIsAddEmployeeModalVisible(false);
  };

  const handleUploadConfirmationModalCancel = () => {
    setIsUploadConfirmationModalVisible(false);
    setEmployeeCount(0);
    setUploadModalContent("");
  };

  const handleUploadSuccessModalClose = () => {
    setIsUploadSuccessModalVisible(false);
    setEmployeeCount(0);
    setUploadModalContent("");
  };

  const handleCopyContent = () => {
    const textarea = document.createElement("textarea");
    textarea.value = addEmployeeModalContent;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopyButtonText(t("admin_dashboard.copied"));
  };

  const emailValidation = {
    type: "email",
    message: t("common.email_validation_error_message"),
  };

  return (
    <div style={{ padding: "16px" }}>
      <Row gutter={[16, 16]} justify="space-between" align="middle">
        <Col xs={24} sm={12}>
          <h3>{t("admin_dashboard.employee_managemenet")}</h3>
        </Col>
        <Col xs={24} sm={12} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={handleExportCsv}>
            {t("admin_dashboard.export_employees")}
          </Button>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <div>
            <h3>{t("admin_dashboard.add_employee")}</h3>
            <div style={{ border: "1px solid #e8e8e8", padding: "16px" }}>
              <Form
                onFinish={handleAddEmployee}
                layout="horizontal"
                form={form}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col span={24} sm={12}>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: t("common.username_null_error_message"),
                        },
                      ]}
                    >
                      <Input
                        prefix={<UserOutlined />}
                        placeholder={t("employeeManagement.employeeName")}
                      />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: t("common.email_null_error_message"),
                        },
                        { ...emailValidation },
                      ]}
                    >
                      <Input
                        prefix={<MailOutlined />}
                        placeholder={t("employeeManagement.email")}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={24}
                    sm={12}
                    style={{ position: "relative", textAlign: "left" }}
                  >
                    <Form.Item
                      style={
                        window.innerWidth <= 576
                          ? {}
                          : { position: "absolute", bottom: 0, left: 0 }
                      }
                    >
                      <Button type="primary" htmlType="submit">
                        {t("admin_dashboard.add_employee_btn")}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <h3>{t("admin_dashboard.bulk_create_employee")}</h3>
          <div style={{ border: "1px solid #ccc", padding: "16px" }}>
            <Row gutter={[16, 16]}>
              <Col span={24} sm={12}>
                <Button
                  icon={<DownloadOutlined />}
                  onClick={downloadCsvTemplate}
                >
                  {t("admin_dashboard.download_csv_template")}
                </Button>
              </Col>
              <Col span={24} sm={12}>
                <Upload
                  showUploadList={false}
                  customRequest={({ file }) => handleUploadCsv(file)}
                >
                  <Button type="primary" icon={<UploadOutlined />}>
                    {t("admin_dashboard.upload_csv")}
                  </Button>
                </Upload>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Modal
        centered
        open={isAddEmployeeModalVisible}
        onCancel={handleAddEmployeeModalClose}
        footer={[
          <Button
            key="copy"
            icon={<CopyOutlined />}
            onClick={handleCopyContent}
          >
            {copyButtonText}
          </Button>,
          <Button key="close" onClick={handleAddEmployeeModalClose}>
            {t("admin_dashboard.close")}
          </Button>,
        ]}
        title={t("admin_dashboard.add_employee_success")}
      >
        <div style={{ margin: "20px" }}>
          {" "}
          <Input value={addEmployeeModalContent} readOnly />
        </div>
      </Modal>

      <Modal
        centered
        open={isUploadConfirmationModalVisible}
        onCancel={handleUploadConfirmationModalCancel}
        footer={[
          <Button key="cancel" onClick={handleUploadConfirmationModalCancel}>
            {t("admin_dashboard.cancel")}
          </Button>,
          <Button key="ok" type="primary" onClick={UploadCsv}>
            {t("admin_dashboard.confirm")}
          </Button>,
        ]}
      >
        <p>{`${employeeCount} ${t("admin_dashboard.employees_to_upload")}`}</p>
      </Modal>
      <Modal
        centered
        open={isUploadSuccessModalVisible}
        onCancel={handleUploadSuccessModalClose}
        footer={[
          <Button key="close" onClick={handleUploadSuccessModalClose}>
            {t("admin_dashboard.close")}
          </Button>,
        ]}
      >
        {/* <p>{`${employeeCount} ${t("admin_dashboard.upload_success")}`}</p> */}
        <p>{uploadModalContent}</p>
      </Modal>
    </div>
  );
};

export default AddEmployee;
