import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        common: {
          login: "Login",
          logout: "Logout",
          username: "Username",
          password: "Password",
          email: "Email",
          username_null_error_message: "Please enter a username",
          email_null_error_message: "Please enter your email address",
          email_validation_error_message: "Please enter a valid email address",
          session_expiered: "Your session has expired. Please login!",
        },
        admin_dashboard: {
          employee_managemenet: "Employee Management",
          add_employee: "Add an Employee",
          bulk_create_employee: "Bulk Create Employees",
          add_employee_btn: "Add Employee",
          download_csv_template: "Download CSV Template",
          upload_csv: "Upload Employee List",
          export_employees: "Export Employee List As CSV",
          generate_reports: "Report Management",
          select_month: "Select Month",
          genrate_monthly_report: "Generate Monthly Report",
          genrate_daily_report: "Generate daily report",
          select_date: "Select Date",
          copy_content: "Copy to Clipboard",
          copied: "Copied",
          add_employee_success: "Employee Added Successfully!",
          close: "Close",
          confirm: "Confirm",
          cancel: "Cancel",
          ok: "Ok",
          monthly_report: "monthly-timeReport",
          all_emloyees: "AllEmployees",
          daily_report: "daily-timeReport",
          generate_report_error:
            "An error occurred while generating the report. There may be no record for the selected month/day",
          add_employee_error:
            "The user is already in the system, Try a different email",
          employees_to_upload:
            "employees' details were found in the uploaded CSV file. Are you sure to continue and upload their details to the system?",
          upload_success:
            "$ Employees were added out of % successfully. Duplicate entries: #",
        },
        employeeManagement: {
          employeeName: "Employee Name",
          email: "Email",
        },
        employeeCard: {
          start: "Start",
          end: "End",
          summary: "Enter your summary",
        },
      },
    },
    jp: {
      translation: {
        common: {
          login: "ログイン",
          logout: "ログアウト",
          username: "ユーザー名",
          password: "パスワード",
          email: "電子メール",
          username_null_error_message: "ユーザー名を入力してください",
          email_null_error_message: "メールアドレスを入力してください",
          email_validation_error_message:
            "有効なEメールアドレスを入力してください",
          session_expiered:
            "セッションの有効期限が切れています。ログインしてください！",
        },
        admin_dashboard: {
          employee_managemenet: "従業員管理",
          add_employee: "従業員を追加する",
          bulk_create_employee: "社員を一括で作成する",
          add_employee_btn: "社員追加",
          download_csv_template: "CSVテンプレートダウンロード",
          upload_csv: "社員名簿のアップロード",
          export_employees: "社員名簿をCSVで書き出す",
          generate_reports: "レポート管理",
          select_month: "月を選択",
          genrate_monthly_report: "マンスリーレポートの作成",
          genrate_daily_report: "日報の作成",
          select_date: "日付を選択",
          copy_content: "クリップボードにコピーする",
          copied: "コピー",
          close: "閉じる",
          add_employee_success: "従業員の追加に成功しました！",
          confirm: "確認",
          cancel: "キャンセル",
          ok: "オッケー",
          monthly_report: "月次報告",
          all_emloyees: "全従業員",
          generate_report_error:
            "レポートの生成中にエラーが発生しました。選択された月/日のレコードがない可能性があります。",
          daily_report: "デイリータイムレポート",
          add_employee_error:
            "ユーザーがすでにシステムに参加している、別の電子メールを試す",
          employees_to_upload:
            "アップロードした CSV ファイル内の従業員の詳細。従業員の詳細をシステムにアップロードし続けてもよろしいですか?",
          upload_success:
            "'$' 従業員は '%' から正常に追加されました。重複したエントリ：'#'",
        },
        employeeManagement: {
          employeeName: "社員名",
          email: "電子メール",
        },
        employeeCard: {
          start: "スタート",
          end: "終了",
          summary: "サマリーを入力してください",
        },
      },
    },
  },
  lng: "jp",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
