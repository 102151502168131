import API from "../config/api";

export const fetchTimeCard = async (id) => {
  let response;

  await API.get(`api/timereports/accesscard/${id}`)
    .then((res) => {
      response = res;
    })
    .catch((err) => {});

  return response;
};
