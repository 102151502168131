import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, message, Input } from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import moment from "moment";
import logo from "../assets/logo.png";
import { t } from "i18next";
import Timer from "../components/Timer";
import "moment/locale/ja";
import { useParams } from "react-router-dom";
import { fetchTimeCard } from "../service/FetchTimeCard";
import { reportTime } from "../service/ReportTime";

const TimeCard = () => {
  const [startedTime, setStartedTime] = useState("");
  const [checkInStatus, setCheckInStatus] = useState(false);
  const [userData, setUserData] = useState();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [paragraphText, setParagraphText] = useState("");
  const { personalurl } = useParams();

  const handleCheckInOut = async () => {
    try {
      if (checkInStatus) {
        setStartedTime(moment());
        setCheckInStatus(false);
        const data = {
          email: email,
          isCheckIn: true,
          cardId: userData.cardId,
          summary: paragraphText,
        };
        await reportTime(data).then(() => {
          fetchTimeCardDetails();
        });
      } else {
        setCheckInStatus(true);
        const data = {
          email: email,
          isCheckIn: false,
          cardId: userData.cardId,
          summary: paragraphText,
        };
        await reportTime(data).then(() => {
          fetchTimeCardDetails();
        });
        setParagraphText("");
      }
    } catch (error) {
      message.error(
        "何らかのエラーが発生しました。後でもう一度試してください。"
      );
    }
  };

  const fetchTimeCardDetails = async () => {
    try {
      const response = await fetchTimeCard(personalurl);
      setUserData(response.data);
      setUserName(response.data.fullname);
      setEmail(response.data.email);
      setCheckInStatus(response.data.checkInStatus);
      setStartedTime(response.data.createdAt);
    } catch (error) {
      message.error("ユーザーが見つかりません");
    }
  };

  useEffect(() => {
    fetchTimeCardDetails();
  }, []);

  const handleParagraphChange = (e) => {
    setParagraphText(e.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {userData && (
        <Card
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>{moment().locale("ja").format("LLL")}</div>
              <img
                src={logo}
                alt="Company Logo"
                style={{ marginLeft: 8, width: 120, height: 40 }}
              />
            </div>
          }
          style={{
            width: "90%",
            maxWidth: 500,
            boxShadow: "0 5px 8px rgba(0, 0, 0, 0.5)",
          }}
        >
          <div style={{ marginBottom: 16 }}>
            <UserOutlined style={{ fontSize: 24, marginRight: 8 }} />
            <span>{userName}</span>
          </div>
          <div style={{ marginBottom: 16 }}>
            <MailOutlined style={{ fontSize: 24, marginRight: 8 }} />
            <span>{email}</span>
          </div>
          {!checkInStatus && (
            <Row
              gutter={[16, 16]}
              justify="center"
              style={{ marginBottom: 16 }}
            >
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Input.TextArea
                  rows={10}
                  placeholder={t("employeeCard.summary")}
                  value={paragraphText}
                  onChange={handleParagraphChange}
                />
              </Col>
            </Row>
          )}

          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={[16, 16]} justify="center" align="middle">
                {checkInStatus && (
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      onClick={handleCheckInOut}
                    >
                      {t("employeeCard.start")}
                    </Button>
                  </Col>
                )}
                {!checkInStatus && (
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      onClick={handleCheckInOut}
                      danger
                    >
                      {t("employeeCard.end")}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {!checkInStatus && (
            <Row gutter={[16, 16]} justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div style={{ textAlign: "center" }}>
                  <span style={{ fontWeight: "bold", fontSize: "24px" }}>
                    <Timer givenTime={startedTime} />
                  </span>
                </div>
              </Col>
            </Row>
          )}
          <Row gutter={[16, 16]} justify="center"></Row>
        </Card>
      )}
    </div>
  );
};

export default TimeCard;
