import React, { useState } from "react";
import { Form, Input, Button, Card, Row, Col, message } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { t } from "i18next";
import { login } from "../service/Login";
import { Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("無効な電子メールアドレス")
    .required("E-mailは必須です。"),
  password: Yup.string().required("パスワードが必要です"),
});

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    setLoading(true);

    const { email, password } = values;

    const credentials = {
      email: email,
      password: password,
    };

    try {
      const response = await login(credentials);
      localStorage.setItem("authToken", response.token);

      setTimeout(() => {
        localStorage.removeItem("authToken");
      }, 82800000); // 23 hours

      setLoading(false);
      navigate("/admin");
    } catch (error) {
      message.error("電子メールまたはパスワードが正しくありません。");
      setLoading(false);
    }
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Col xs={24} sm={16} md={12} lg={8}>
        <Card style={{ boxShadow: "0 5px 8px rgba(0, 0, 0, 0.5)" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 16,
            }}
          >
            <div>
              <span style={{ fontSize: 18, fontWeight: "bold" }}>
                {t("common.login")}
              </span>
            </div>
            <div>
              <img src={logo} alt="Logo" style={{ width: 120, height: 40 }} />
            </div>
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form name="loginForm" onFinish={handleSubmit}>
                <Form.Item
                  name="email"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      <MailOutlined /> {t("common.email")}
                    </span>
                  }
                  validateStatus={touched.email && errors.email ? "error" : ""}
                  help={touched.email && errors.email}
                >
                  <Input
                    placeholder="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      <LockOutlined /> {t("common.password")}
                    </span>
                  }
                  validateStatus={
                    touched.password && errors.password ? "error" : ""
                  }
                  help={touched.password && errors.password}
                >
                  <Input.Password
                    placeholder="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {t("common.login")}
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Formik>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
