import API from "../config/api";

export const reportTime = async (data) => {
  try {
    const response = await API.post(`api/timereports/report`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
