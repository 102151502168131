import API from "../config/api";
import { message } from "antd";
import { t } from "i18next";
export const bukCreateEmployee = async (data) => {
  try {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      message.error(t("common.session_expiered"));
      return;
    }

    const response = await API.post("api/users/bulkadd", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};
