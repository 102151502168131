import React, { useState } from "react";
import { Button, message, DatePicker, Row, Col, Divider } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { exportTimeReport } from "../service/ExportTimeReport";
import moment from "moment";
import "moment/locale/ja";
import Papa from "papaparse";

const GenerateReports = () => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);

  const handleGenerateMonthlyReport = async (selectedMonth) => {
    const dateObj = new Date(selectedMonth);
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();

    const filter = {
      month: month,
      year: year,
    };

    const dataObj = {
      filter: filter,
      isMonthly: true,
    };
    var filename = `${month}-${year}-${t(
      "admin_dashboard.monthly_report"
    )}.csv`;
    try {
      const response = await exportTimeReport(dataObj);
      const data = response.data;

      const csvContent = "\uFEFF" + data; // Add Byte Order Mark (BOM) for UTF-8 encoding

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      message.error(t("admin_dashboard.generate_report_error"));
      setSelectedMonth(null);
    }
  };

  const handleGenerateDailyReport = async (selectedDay) => {
    const dateString = selectedDay.toString();
    const rearrangedDateString = dateString.replace(
      /^(\w+), (\d+) (\w+) (\d{4}) (.*)$/,
      "$2 $3 $4 $5"
    );

    const dateObj = new Date(rearrangedDateString);

    const date = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();

    const filter = {
      month: month,
      year: year,
      date: date,
    };

    const dataObj = {
      filter: filter,
      isMonthly: false,
    };
    var filename = `${date}-${month}-${year}-${t(
      "admin_dashboard.daily_report"
    )}.csv`;
    try {
      const response = await exportTimeReport(dataObj);
      const data = response.data;

      const csvContent = "\uFEFF" + data; // Add Byte Order Mark (BOM) for UTF-8 encoding

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      setSelectedDay(null);
      message.error(t("admin_dashboard.generate_report_error"));
    }
  };

  const datePickerStyle = {
    backgroundColor: "white",
    color: "black",
    borderColor: "#999999",
  };

  return (
    <div style={{ margin: "1%" }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <h3>{t("admin_dashboard.generate_reports")}</h3>
          <div
            style={{
              border: "1px solid #d9d9d9",
              padding: "16px",
              marginBottom: "16px",
            }}
          >
            <Row
              gutter={[16, 16]}
              justify="space-between"
              style={{ marginBottom: "16px" }}
            >
              <Col xs={24} sm={12}>
                <DatePicker.MonthPicker
                  placeholder={t("admin_dashboard.select_month")}
                  onChange={(date) => setSelectedMonth(date)}
                  style={datePickerStyle}
                  suffixIcon={<CalendarOutlined style={{ color: "black" }} />}
                  className="custom-datepicker"
                />
              </Col>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  onClick={() => handleGenerateMonthlyReport(selectedMonth)}
                  disabled={!selectedMonth}
                >
                  {t("admin_dashboard.genrate_monthly_report")}
                </Button>
              </Col>
            </Row>
            <Divider />
            <Row gutter={[16, 16]} justify="space-between">
              <Col xs={24} sm={12}>
                <DatePicker
                  placeholder={t("admin_dashboard.select_date")}
                  onChange={(date) => setSelectedDay(date)}
                  style={datePickerStyle}
                  suffixIcon={<CalendarOutlined style={{ color: "black" }} />}
                  className="custom-datepicker"
                />
              </Col>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  onClick={() => handleGenerateDailyReport(selectedDay)}
                  disabled={!selectedDay}
                >
                  {t("admin_dashboard.genrate_daily_report")}
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GenerateReports;
