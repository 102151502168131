import React, { useEffect, useState } from "react";
import { Button, Col, Row, Card } from "antd";
import { useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import AddEmployee from "../components/AddEmployee";
import GenerateReports from "../components/GenerateReports";
import logo from "../assets/logo.png";
import { t } from "i18next";
import LanguageSelector from "../components/LanguageSelector";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [authToken] = useState(localStorage.getItem("authToken"));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        navigate("/");
      }
    }, 10000);

    if (!authToken) {
      navigate("/");
    }

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/");
  };

  return (
    <div>
      <Card
        bordered
        style={{
          borderRadius: "5px",
          marginBottom: "5px",
          marginTop: "5px",
          marginLeft: "20px",
          marginRight: "20px",
          borderColor: "rgba(0, 0, 0, 0.2)",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "20px" }}>
            <img
              src={logo}
              alt="Logo"
              style={{ width: "110px", height: "40px" }}
            />
          </div>
          <div style={{ marginLeft: "auto" }}>
            <Button
              style={{
                marginRight: "20px",
              }}
              type="primary"
              onClick={handleLogout}
              icon={<LogoutOutlined />}
            >
              {t("common.logout")}
            </Button>

            {/* <LanguageSelector /> */}
          </div>
        </div>
      </Card>
      <Card
        bordered
        style={{
          borderRadius: "5px",
          marginBottom: "5px",
          marginTop: "5px",
          marginLeft: "20px",
          marginRight: "20px",
          borderColor: "rgba(0, 0, 0, 0.2)",
          minHeight: "80vh",
        }}
      >
        <div style={{ flex: 1 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <AddEmployee />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <GenerateReports />
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default AdminDashboard;
